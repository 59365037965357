<template>
  <div>
    <content-not-view v-if="!subPermission.index" />
    <loading v-else-if="isLoading" />
    <b-card v-else>
      <div
        class="d-flex justify-content-end mb-1 px-1"
        v-if="subPermission.store"
      >
        <div>
          <b-button :to="{ name: 'AddSubjects' }" variant="primary">{{
            $t("g.subjects/listOfSubjects/button/add_subject")
          }}</b-button>
        </div>
      </div>
      <b-row class="mt-2 pl-1">
        <card
          class="col-sm-11 col-md-5 col-lg-5 col-xl-5 col-11 pb-1"
          v-for="item in list"
          :key="item.id"
          :item="item"
          :permission="subPermission"
          @deleteSubject="deleteAlert"
        />
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BAvatar,
  BRow,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import loading from "@/components/loading/loading.vue";
import Ripple from "vue-ripple-directive";
import card from "@/components/subjects/listOfSubjects/InfoCard/index.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
export default {
  name: "ListOfEducationsLevels",
  components: {
    BAvatar,
    BRow,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    BDropdownItem,
    BDropdown,
    VBTooltip,
    loading,
    card,
    contentNotView,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      list: [],
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "subjects");
      return this.$store.state.userData.sub_Permission;
    },
  },
  mounted() {
    this.getSubjectsList();
  },

  methods: {
    getSubjectsList() {
      this.$http.get("admin/subjects").then((res) => {
        this.list = res.data.data;
        this.isLoading = false;
      });
    },
    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteEduLevel(id);
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    deleteEduLevel(id) {
      this.$http
        .delete(`admin/subjects/${id}`)
        .then((res) => {
          if (res.status === 200) {
            this.getSubjectsList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
