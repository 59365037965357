<template>
  <div class="enrolled-course-card">
    <div class="body-card">
      <div class="course-image">
        <img
          v-if="item.image && item.image.length"
          :src="item.image[0].path"
          :alt="`course ${item.name}`"
          class="img"
        />
        <img
          v-else
          src="@/assets/images/futuer-icons/logoMid.svg"
          :alt="`course ${item.name}`"
          class="img"
        />
      </div>
      <b-row class="ml-1 course-content w-100">
        <div class="status d-flex justify-content-center w-100 pr-2">
          <h4 class="course-name">{{ item.name }}</h4>
          <b-badge :variant="item.is_available ? 'success' : 'danger'" >{{ item.is_available ? $t('g.available') : $t('g.not_available')}}</b-badge>
        </div>
        <b-row class="pt-2 d-flex justify-content-end w-100">
          <b-col
            cols="9"
            class="d-flex justify-content-end"
            v-if="permission.update || permission.destroy"
          >
            <b-button
              v-if="permission.update"
              variant="primary"
              :to="{
                name: 'editSubjects',
                params: {
                  id: item.id,
                },
              }"
            >
              {{ $t("g.edit") }}</b-button
            >
            <b-button
              v-if="permission.destroy"
              variant="outline-danger "
              class="ml-1"
              @click="$emit('deleteSubject', item.id)"
              >{{ $t("g.delete") }}</b-button
            >
          </b-col>
        </b-row>
      </b-row>
    </div>
  </div>
</template>
<script>
import { BRow, BCard, BFormGroup, BCol, BButton, BBadge } from "bootstrap-vue";
export default {
  name: "card",
  props: ["item", "permission"],
  components: {
    BRow,
    BCard,
    BFormGroup,
    BCol,
    BButton,
    BBadge
  },
};
</script>
<style lang="scss">
@import "./card.scss";
</style>
